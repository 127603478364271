<template>
  <div class="app">
    <van-nav-bar :fixed="true" left-arrow placeholder @click-left="onClickLeft" :title="$t('newAdd.AddBankCardTitle')" />
    <div class="list" pullTips="false">
      <div class="list-space"></div>

      <div class="list-item">
        <!-- language-ch 真实姓名 -->
        <!-- <text class="list-item-title">Real Name</text> -->
        <span class="list-item-title">{{ $t("newAdd.RealName") }}</span>
        <!-- language-ch 请输入持卡人真实姓名 -->
        <input :hideDoneButton="false" class="list-item-input" v-model="input_name"
          :placeholder="$t('newAdd.RealName')" />
      </div>
      <div class="list-line"></div>
      <!-- ifsc的一行 -->
      <!-- <div class="list-item">
        <span class="list-item-title">{{ $t('newAdd.IFSCCODE') }}</span>
        <input
          :hideDoneButton="false"
          class="list-item-input"
          v-model="ifsc_code"
          :placeholder="$t('newAdd.IFSCCODE')"
        />
      </div>
      <div class="list-line"></div> -->

      <div class="list-item" v-if="verConfig.bankInput">
        <!-- language-ch 银行名称-以前的注释 -->
        <span class="list-item-title">{{ $t("newAdd.BankName") }}</span>
        <!-- language-ch 请输入银行名称-以前的注释 -->
        <input :hideDoneButton="false" class="list-item-input" v-model="input_bank"
          :placeholder="$t('newAdd.BankName')" />
      </div>
      <van-field v-model="bankshowVal" is-link readonly :label="$t('newAdd.BankName')"
        :placeholder="$t('newAdd.BankName')" @click="show = true" v-if="!verConfig.bankInput" />
      <van-popup v-model="show" round position="bottom">
        <van-cascader v-model="input_bank" :title="$t('newAdd.BankName')" :options="bankArr" v-if="country != 'tr'"
          :placeholder="$t('newAdd.BankName')" @change="cascaderChange" @close="show = false" />
        <van-cascader v-model="input_bank" :title="$t('newAdd.BankName')" :options="trBankArr" v-if="country == 'tr'"
          :placeholder="$t('newAdd.BankName')" @change="cascaderChange" @close="show = false" />
      </van-popup>
      <!-- <van-field
        v-if="verConfig.bankInput"
        v-model="input_bank"
        :label="$t('newAdd.BankName')"
        :placeholder="$t('newAdd.BankName')"
      /> -->
      <div class="list-line"></div>

      <div class="list-item">
        <!-- language-ch 银行卡号 -->
        <!-- <text class="list-item-title">Card No</text> -->
        <span class="list-item-title">{{ $t("newAdd.BankNum") }}</span>
        <!-- language-ch 请输入银行卡号 -->
        <input :hideDoneButton="false" class="list-item-input" v-model="input_card" :placeholder="$t('newAdd.BankNum')"
          type="text" onkeyup="value=value.replace(/[\W]/g,'')"
          onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[^\d]/g,''))" />
      </div>
      <!-- 输入邮箱 -->

      <!-- <div class="list-line"></div> -->
      <!-- <div class="list-item">
        language-ch 开户支行
        <text class="list-item-title">Account opening branch</text>
        language-ch 请输入开户支行
        <input
          :hideDoneButton="false"
          class="list-item-input"
          v-model="input_open"
          placeholder="Please enter the opening branch"
        />
      </div> -->
      <!-- <div class="list-line"></div>
      <div class="list-item">
        <text class="list-item-title">UPI</text>
        <input
          :hideDoneButton="false"
          class="list-item-input"
          v-model="upi"
          placeholder="UPI/BANK ACCOUNT NUMBER"
        />
      </div> -->

      <div class="list-line"></div>
      <div class="list-item">
        <span class="list-item-title">{{ $t("newAdd.Mobile") }}</span>
        <input :hideDoneButton="false" class="list-item-input" v-model="mobile" :placeholder="$t('newAdd.MobileNum')" />
      </div>

      <!-- 邮件的一行 -->
      <!-- <div class="list-line"></div>
      <div class="list-item">
        <span class="list-item-title">{{ $t("newAdd.Email") }}</span>
        <input
          :hideDoneButton="false"
          class="list-item-input"
          v-model="email"
          :placeholder="$t('newAdd.EmailRequired')"
        />
      </div> -->

      <div>
        <!-- language-ch 注意：银行卡信息为重要信息，添加后禁止修改！ -->
        <span class="list-red">{{ $t("newAdd.AddBankCardNote") }}</span>
      </div>
      <!-- language-ch 提 交 -->
      <button @click="itemClick()" class="button">
        {{ $t("newAdd.Submit") }}
      </button>
    </div>
  </div>
</template>



<script>
import { GET_USER_BANK_INFO, COMMIT_USER_BANK_INFO } from "@/api";
import { count } from "@/utils";
import Cookies from "js-cookie";
import { NavBar, Dialog, Toast, Cascader, Popup, Field } from "vant";
import "vant/lib/button/style";
export default {
  components: {
    [NavBar.name]: NavBar,
    [Dialog.Component.name]: Dialog.Component,
    [Toast.name]: Toast,
    [Cascader.name]: Cascader,
    [Popup.name]: Popup,
    [Field.name]: Field,
  },
  data() {
    return {
      show: false,
      country: Cookies.get("language"),
      // a
      bankshowVal: "",
      dataid: 0,
      input_name: "",
      input_bank: "",
      input_card: "",
      input_open: "",
      mobile: "",
      upi: "",
      email: "",
      ifsc_code: "",
      bankArr: [
        {
          value: "IDPT0001",
          text: "Canara Bank",
        },
        {
          value: "IDPT0002",
          text: "	DCB Bank",
        },
        {
          value: "IDPT0003",
          text: "	Federal Bank",
        },
        {
          value: "IDPT0004",
          text: "HDFC Bank",
        },
        {
          value: "IDPT0005",
          text: "Punjab National Bank",
        },
        {
          value: "IDPT0006",
          text: "Indian Bank",
        },
        {
          value: "IDPT0007",
          text: "ICICI Bank",
        },
        {
          value: "IDPT0008",
          text: "Syndicate Bank",
        },
        {
          value: "IDPT0009",
          text: "Karur Vysya Bank",
        },
        {
          value: "IDPT0010",
          text: "Union Bank of India",
        },
        {
          value: "IDPT0011",
          text: "Kotak Mahindra Bank",
        },
        {
          value: "IDPT0012",
          text: "IDFC First Bank",
        },
        {
          value: "IDPT0013",
          text: "Andhra Bank",
        },
        {
          value: "IDPT0014",
          text: "Karnataka Bank",
        },
        {
          value: "IDPT0015",
          text: "icici corporate bank",
        },
        {
          value: "IDPT0016",
          text: "Axis Bank",
        },
        {
          value: "IDPT0017",
          text: "UCO Bank",
        },
        {
          value: "IDPT0018",
          text: "South Indian Bank",
        },
        {
          value: "IDPT0019",
          text: "Yes Bank",
        },
        {
          value: "IDPT0020",
          text: "Standard Chartered Bank",
        },
        {
          value: "IDPT0021",
          text: "State Bank of India",
        },
        {
          value: "IDPT0022",
          text: "Indian Overseas Bank",
        },
        {
          value: "IDPT0023",
          text: "Bandhan Bank",
        },
        {
          value: "IDPT0024",
          text: "Central Bank of India",
        },
        {
          value: "IDPT0025",
          text: "Bank of Baroda",
        },
      ],
      trBankArr: [
        {
          value: "Garanti-Bank",
          text: "Garanti Bankası",
        },
        {
          value: "is-Bank",
          text: "İş Bankası",
        },
        {
          value: "Vakifbank",
          text: "Vakıfbank",
        },
        {
          value: "Halk-Bank",
          text: "Halk Bankası",
        },
        {
          value: "Denizbank",
          text: "Denizbank",
        },
        {
          value: "Akbank",
          text: "Akbank",
        },
        {
          value: "Turkiye-Finans",
          text: "Türkiye Finans Bank",
        },
        {
          value: "INGBank",
          text: "INGBank",
        },
        {
          value: "Kuveytturk",
          text: "Kuveyttürk",
        },
        {
          value: "Ziraat-Bank",
          text: "Ziraat Bankası",
        },
        {
          value: "Yapi-Kredi",
          text: "Yapı Kredi",
        },
        {
          value: "Sekerbank",
          text: "Şekerbank",
        },
        {
          value: "Albaraka",
          text: "Albaraka",
        },
        {
          value: "Aktif-Bank",
          text: "Aktif Bank",
        },
      ],
    };
  },

  mounted() {
    this.getUserBankInfo();
  },

  methods: {
    cascaderChange(item) {
      this.show = false;
      this.bankshowVal = item.selectedOptions[0].text;
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    itemClick() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      COMMIT_USER_BANK_INFO({
        id: this.dataid,
        name: this.input_name,
        bank: this.input_bank,
        card: this.input_card,
        open: this.input_open,
        mobile: this.mobile,
        upi: this.upi,
        email: this.email,
        ifsc_code: this.ifsc_code,
      })
        .then((r) => {
          Toast.clear();
          if (r.data.ret === 1) {
            Toast.clear();
            Dialog.alert({
              title: this.$t("newAdd.warmTitle"),
              message: r.data.msg,
              confirmButtonText: this.$t("My.Button.Two"),
              cancelButtonText: this.$t("My.Button.One"),
            }).then(() => {
              this.$router.go(-1);
            });
          } else {
            Toast.clear();
            Toast(r.data.msg);
          }
        })
        .catch((e) => {
          Toast.clear();
          console.error(e);
        });
    },
    getUserBankInfo() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      GET_USER_BANK_INFO()
        .then((r) => {
          Toast.clear();
          if (r.data.ret === 1 && count(r.data.data) > 0) {
            Toast.clear();
            this.dataid = r.data.data[0].id;
            this.input_name = r.data.data[0].name;
            this.input_bank = r.data.data[0].bank;
            if (this.country == "tr") {
              for (let i = 0; i < this.trBankArr.length; i++) {
                const item = this.trBankArr[i];
                if (this.input_bank == item.value) {
                  this.bankshowVal = item.title;
                }
              }
            } else {
              for (let i = 0; i < this.bankArr.length; i++) {
                const item = this.bankArr[i];
                if (this.input_bank == item.value) {
                  this.bankshowVal = item.title;
                }
              }
            }
            this.input_card = r.data.data[0].card;
            this.input_open = r.data.data[0].open;
            this.mobile = r.data.data[0].mobile;
            this.upi = r.data.data[0].upi;
            this.email = r.data.data[0].email;
            this.ifsc_code = r.data.data[0].ifsc_code;
          }
        })
        .catch((e) => {
          Toast.clear();
          console.error(e);
        });
    },
  },
};
</script>

<style scoped lang="less">
.app {
  flex: 1;
  width: 100%;
  height: 100vh;
  background-color: #f4f8f9;
  /deep/ .van-nav-bar__content {
    background-color: var(--navBg);
    height: calc(50rem / 16);
  }
  /deep/ .van-nav-bar__title{
    color: #fff;
  }
  /deep/ .van-nav-bar .van-icon{
    color: #fff;
  }
  

  >.title {
    height: calc(49rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffba04;
    color: #464646;
    font-weight: 400;
    font-size: calc(18rem / 16);
    position: relative;
    width: 100%;
    margin-top: 0;

    >.explain-box {
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: calc(16rem / 16);
      position: absolute;
      top: 50%;
      right: calc(16rem / 16);
      transform: translate(0, -50%);
      color: #fff;

      >p {
        // padding-bottom: calc(4rem / 16);
        margin-left: calc(3rem / 16);
      }
    }
  }
}

.list {
  flex: 1;
  width: 100%;
}

.list-space {
  width: 100%;
  height: calc(10rem / 16);
  background-color: #f4f8f9;
}

.list-item {
  width: 100%;
  height: calc(50rem / 16);
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
}

.list-item-title {
  font-size: calc(14rem / 16);
  margin-left: calc(12rem / 16);
  margin-right: calc(17rem / 16);
  color: #242424;
}

.list-item-input {
  flex: 1;
  font-size: calc(14rem / 16);
  height: calc(40rem / 16);
  padding-left: calc(12rem / 16);
  border: none;
}

.list-red {
  margin-top: calc(62rem / 16);
  color: #dc1323;
  font-size: calc(14rem / 16);
  text-align: center;
  display: block;
}

.list-line {
  width: 100%;
  height: 1px;
  background-color: #ececec;
}

.button {
  width: calc(340rem / 16);
  height: calc(43rem / 16);
  line-height: calc(43rem / 16);
  font-size: calc(14rem / 16);
  margin: 0 auto;
  margin-top: calc(11rem / 16);
  // margin-left: calc(17rem / 16);
  // background-color: #dc1323;
  background-color: var(--navBg);
  border-radius: calc(8rem / 16);
  color: #fff;
  border: none;
  display: block;
}
</style>